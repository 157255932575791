<template>
  <div>
    <b-modal id="SelectWalletModal"
             v-model="modalShow"
             @hide="statChange"
             footer-class="justify-content-center"
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">지갑 선택하기</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <template>
        <p>거래하실 지갑을 선택해주세요.</p>
         <div v-show="coinType === 'KLAY'" :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}">
        <div v-for="(wallet) in walletList" :key="wallet.name">
          <b-button variant="none" @click="ok(wallet.name)" :style="{width: '100px'}">
            <img :src="wallet.image" />
            <p>{{wallet.name}}</p>
          </b-button>
        </div>
      </div>
      <div v-show="coinType === 'TRX'" :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}">
        <div v-for="(wallet) in tronList" :key="wallet.name">
          <b-button variant="none" @click="ok(wallet.name)" :style="{width: '100px'}">
            <img :src="wallet.image" :style="{width: '40px', height: '40px'}" />
            <p>{{wallet.name}}</p>
          </b-button>
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel">취소</b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'SelectWalletModal',
  props: [
    'value', 'coinType'
  ],
  data () {
    return {
      modalShow: this.value,
      walletList: [
        {
          image: require('../../../assets/images/wallet/metamask.svg'),
          name: 'Metamask'
        },
        {
          image: require('../../../assets/images/wallet/kaikas.svg'),
          name: 'Kaikas'
        },
        {
          image: require('../../../assets/images/wallet/klip.svg'),
          name: 'Klip'
        }
      ],
      tronList: [
        {
          image: require('../../../assets/images/wallet/tron.png'),
          name: 'TronLink'
        }
        // {
        //   image: require('../../../assets/images/wallet/trust.png'),
        //   name: 'Trust'
        // }
      ]
    }
  },
  methods: {
    statChange () {
      this.$emit('setWalletModal', false)
    },
    ok (name) {
      this.$emit('setWallet', name)
      this.$emit('setWalletModal', false)
      this.$emit('action')
    }
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  }
}
</script>
