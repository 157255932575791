import Caver from 'caver-js'
// import { abi } from '../../abi/abi'
// import { crowdsaleAbi } from '../../abi/crowdsaleAbi'
import Decimal from 'decimal.js'
import wallet from '../wallet'
import axios from 'axios'

// const network = 1001 // klaytn testnet 1001
// const chainId = '0x3e9' // klaytn testnet 0x3e9
const tokenAddress = '0x981AeB68B7A9d1B3d9341636D0f45660995C6Af5'
const crowdsaleAddress = '0xe92C60dfEc285704b8394212faf40C8CDC42997e'
// 스테이킹 풀 주소
const stakingAddress = '0x87e02340c9c5dab434d2e9f5cdbc3da06b8f47da'
const SERVER_URL = 'https://a2a-api.klipwallet.com/v2/a2a'
const config = {
  headers: {
    'Content-Type': 'application/json'
  }
}
// const { klaytn } = window
const caver = new Caver('https://public-node-api.klaytnapi.com/v1/cypress')// 클립의 경우에는 rpc 노드 주소만 바꾸고 요청하면 됨
let interval

async function getResult (requestKey) {
  return axios.get(`${SERVER_URL}/result?request_key=${requestKey}`).then((res) => res.data)
}

/**
   * 지갑 연결
   * @param {*} setAddress action setAddress
   * @param {*} setAmount action setAmount
   */
// async function connect () {
// type: ['connect', 'next']
async function connect (type, isMobile, setKlipQR, setKlipModal, setWalletModal, setModal, setAddress, setAmount, action, setWithdrawModal) {
  try {
    const data = JSON.stringify({
      bapp: {
        name: 'TIPS'
      },
      type: 'auth'
    })

    const requestKey = await axios.post(`${SERVER_URL}/prepare`, data, config).then((res) => res.data.request_key)
    const myRequestAuthURL = 'https://klipwallet.com/?target=/a2a?request_key=' + requestKey
    if (isMobile) {
      setKlipModal(false)
      const popup = window.open()
      if (popup === null || popup === undefined) {
        alert('사파리 팝업 허용을 해주세요.')
      }
      popup.location.assign(myRequestAuthURL)
    } else {
      setKlipQR(myRequestAuthURL)
      setKlipModal(true)
    }

    interval = setInterval(() => {
      getResult(requestKey).then(async (res) => {
        if (res.status === 'completed') {
          setAddress(res.result.klaytn_address)
          // const checkChain = chainChanged()
          // if (!checkChain) {
          //   // 문제가 있음...
          //   return false
          // }
          const weiBalance = await caver.klay.getBalance(res.result.klaytn_address)
          const balance = caver.utils.convertFromPeb(weiBalance)
          setAmount(balance)
          setKlipQR('')
          setKlipModal(false)
          setWalletModal(false)
          if (type === 'next') {
            if (action === 'withdraw') {
              setWithdrawModal()
            } else {
              setModal(true)
            }
          }
          clearInterval(interval)
        }
      })
    }, 3000)
  } catch (err) {
    return false
  }
}

/**
   * 토큰 구매(지갑으로 바로)
   * @param {*} amount Klaytn 송금양
   * @param {*} myAddress Klip 주소
   * @param {*} setKlipQR QR 내용 업데이트
   * @param {*} statKilpModal QR 모달 열기
   */
async function buy (isMobile, amount, myAddress, setKlipQR, statKilpModal, statModal) {
  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    const abi = JSON.stringify({
      inputs: [],
      name: 'invest',
      outputs: [],
      stateMutability: 'payable',
      type: 'function'
    })
    const params = JSON.stringify([])
    const data = JSON.stringify({
      bapp: {
        name: 'TIPS',
        callback: {}
      },
      chain: 'klaytn',
      type: 'execute_contract',
      transaction: {
        from: myAddress,
        to: crowdsaleAddress,
        value: caver.utils.convertToPeb(amount.toString(), 'KLAY'),
        abi: abi,
        params: params
      }
    })
    const requestKey = await axios.post(`${SERVER_URL}/prepare`, data, config).then((res) => res.data.request_key)
    const myRequestSendURL = 'https://klipwallet.com/?target=/a2a?request_key=' + requestKey
    if (isMobile) {
      statKilpModal(false)
      const popup = window.open()
      if (popup === null || popup === undefined) {
        alert('사파리 팝업 허용을 해주세요.')
      }
      popup.location.assign(myRequestSendURL)
    } else {
      setKlipQR(myRequestSendURL)
      statKilpModal(true)
    }
    const interval = setInterval(() => {
      getResult(requestKey).then((res) => {
        if (res.status === 'completed') {
          setKlipQR('')
          statKilpModal(false)
          clearInterval(interval)
          alert('구매 성공했습니다.')
          wallet.purchaseByWallet(amount * 2, 1, myAddress)
        }
      })
    }, 3000)
  } catch (err) {
    // console.log(err)
    return false
  }
}

/**
   * 토큰 구매(충전)
   * @param {*} amount KLAY 송금양
   * @param {*} decimalPolyt TIPS 충전양
   * @param {*} ownCoin TIPS 보유양
   * @param {*} walletCoin 지갑에 보유중인 TIPS
   * @param {*} updateCoin 보유중인 TIPS update
   * @param {*} setAmount 지갑 TIPS update
   */
async function charging (isMobile, amount, myAddress, setKlipQR, statModal, statKilpModal, decimalPolyt, ownCoin, walletCoin, updateCoin, setAmount) {
  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    const data = JSON.stringify({
      bapp: {
        name: 'TIPS',
        callback: {}
      },
      chain: 'klaytn',
      type: 'send_klay',
      transaction: {
        from: myAddress,
        to: stakingAddress,
        amount: amount.toString()
      }
    })
    const requestKey = await axios.post(`${SERVER_URL}/prepare`, data, config).then((res) => res.data.request_key)
    const myRequestSendURL = 'https://klipwallet.com/?target=/a2a?request_key=' + requestKey
    if (isMobile) {
      statKilpModal(false)
      const popup = window.open()
      if (popup === null || popup === undefined) {
        alert('사파리 팝업 허용을 해주세요.')
      }
      popup.location.assign(myRequestSendURL)
    } else {
      setKlipQR(myRequestSendURL)
      statKilpModal(true)
    }
    const interval = setInterval(() => {
      getResult(requestKey).then((res) => {
        if (res.status === 'completed') {
          setKlipQR('')
          statKilpModal(false)
          clearInterval(interval)
          wallet.purchase(amount, myAddress).then(res => {
            if (res.data.resultCode === 'false') {
              alert('서버 오류')
              return
            }
            updateCoin(Decimal.add(ownCoin, decimalPolyt))
            setAmount(Decimal.sub(walletCoin, amount))
          })
          alert('충전 성공했습니다.')
          window.location.reload()
        }
      })
    }, 3000)
  } catch (err) {
    // console.log(err)
    return false
  }
}

/**
   * 지갑 스테이킁
   * @param {string} address KLAY 송금양
   * @param {*} amount KLAY 송금양
   * @param {*} decimalPolyt TIPS 충전양
   * @param {*} ownCoin TIPS 보유양
   * @param {*} walletCoin 지갑에 보유중인 TIPS
   * @param {*} updateCoin 보유중인 TIPS update
   * @param {*} setAmount 지갑 TIPS update
   */
async function walletStaking (address, amount, isMobile, statKilpModal, setKlipQR) {
  // const caver = new Caver('https://public-node-api.klaytnapi.com/v1/cypress')// chainId별로 node url 가져와야 함
  // const abi = JSON.stringify({
  //   constant: false,
  //   inputs: [
  //     {
  //       name: '_to',
  //       type: 'address'
  //     },
  //     {
  //       name: '_value',
  //       type: 'uint256'
  //     }
  //   ],
  //   name: 'transfer',
  //   outputs: [
  //     {
  //       name: '',
  //       type: 'bool'
  //     }
  //   ],
  //   payable: false,
  //   stateMutability: 'nonpayable',
  //   type: 'function'
  // })
  // const params = JSON.stringify([stakingAddress, caver.utils.convertToPeb(amount, 'KLAY')])
  // const data = JSON.stringify({
  //   bapp: {
  //     name: 'KTIPS'
  //   },
  //   type: 'execute_contract',
  //   transaction: {
  //     from: address,
  //     to: tokenAddress,
  //     value: '0',
  //     abi: abi,
  //     params: params
  //   }
  // })
  // -d '{"bapp": { "name" : "My BApp" }, "chain": "klaytn", "type": "send_token", "transaction": { "contract": "0xdc8c8d2CD5829dE8e8a31Fc595D69c4B403e9dD8", "from": "0xcD1722f2947Def4CF144679da39c4C32bDc35681", "to": "0x85c17299e9462e035c149847776e4edb7f4b2aa9", "amount": "100" } }' \
  const data = JSON.stringify({
    bapp: {
      name: 'TIPS'
    },
    chain: 'klaytn',
    type: 'send_token',
    transaction: {
      contract: tokenAddress,
      from: address,
      to: stakingAddress,
      amount: amount
      // amount: caver.utils.convertToPeb(amount.toString(), 'KLAY')
    }
  })
  const requestKey = await axios.post(`${SERVER_URL}/prepare`, data, config).then((res) => res.data.request_key)
  const myRequestSendURL = 'https://klipwallet.com/?target=/a2a?request_key=' + requestKey
  if (isMobile) {
    statKilpModal(false)
    const popup = window.open()
    if (popup === null || popup === undefined) {
      alert('사파리 팝업 허용을 해주세요.')
    }
    popup.location.assign(myRequestSendURL)
  } else {
    setKlipQR(myRequestSendURL)
    statKilpModal(true)
  }
  const interval = setInterval(() => {
    getResult(requestKey).then((res) => {
      if (res.status === 'completed') {
        setKlipQR(null)
        statKilpModal(false)
        clearInterval(interval)
      }
    })
  }, 3000)
}

export default {
  connect, buy, charging, walletStaking
}
