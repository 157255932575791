<template>
  <div>
    <b-modal id="kilpQRModal"
             v-model="modalShow"
             @hide="statChange"
             footer-class="justify-content-center"
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">QR을 스캔 해주세요.</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <template>
        <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}">
          <VueQRCodeComponent :text="this.qr"
           />
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel">취소</b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: 'KilpQRModal',
  components: {
    VueQRCodeComponent
  },
  props: [
    'value', 'qr'
  ],
  data () {
    return {
      modalShow: this.value
    }
  },
  mounted () {},
  methods: {
    statChange () {
      this.$emit('statKilpModal')
    }
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  }
}
</script>
