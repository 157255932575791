import client from '../index'
import { v4 as uuidv4 } from 'uuid'

export default {
  /**
   * 토큰 충전
   * @param {number} coin 충전할 수량
   * @param {string} address 내 지갑주소
   * @param {*} coinType 0: klay, 1: tips, 2: kdia
   */
  async purchase (coin, address, coinType = 0) {
    const postData = {
      coin: coin,
      coin_type: coinType,
      address: address
    }
    try {
      return client.json.post('/purchase', postData)
    } catch (err) {
      // console.log('<POST> wallet.purchase')
      // console.log(err)
    }
  },

  /**
   * 토큰 구매
   * @param {number} coin 구매할 수량
   * @param {*} coinType 0: klay, 1: tips, 2: kdia
   * @param {*} address 구매한 주소
   */
  async purchaseByWallet (coin, coinType = 1, address) {
    const postData = {
      coin: coin,
      coin_type: coinType,
      to_address: address
    }
    try {
      return client.json.post('/purchase/tips', postData)
    } catch (err) {
      // console.log('<POST> wallet.purchase')
      // console.log(err)
    }
  },

  /**
   * 유저 입출금 내역
   * @param {number} count 보여줄 게시글 수
   * @param {number} nowPage 현재 페이지 번호 0부터 시작
   */
  async myDWList (count, nowPage) {
    try {
      const postData = {
        count: count,
        nowpage: nowPage,
        in_out: 3
      }
      return client.json.get('/wallet/asking', { params: postData })
    } catch (err) {
      // console.log('<POST> wallet.stakingCancelWithout')
      // console.log(err)
    }
  },

  /**
   * tips 사용 내역
   * @param {number} count 보여줄 게시글 수
   * @param {number} nowPage 현재 페이지 번호 0부터 시작
   */
  async useaeList (count, nowPage) {
    try {
      const postData = {
        count: count,
        nowpage: nowPage
      }
      return client.json.get('/coin-history/info', { params: postData })
    } catch (err) {
      // console.log('<POST> wallet.stakingCancelWithout')
      // console.log(err)
    }
  },

  /**
   * 거래소에서 입금 요청
   * * @param {String} address 보낸 지갑 주소
   * * @param {number} coin 보낸 klay양
   * * @param {number} coinType 0: klay, 1: tips, 2: kida
   */
  async askingPurchase (address, coin, coinType) {
    try {
      const postData = {
        address: address,
        coin: coin,
        coin_type: coinType
      }
      return client.json.post('/wallet/asking', postData)
    } catch (err) {
      // console.log('<POST> wallet.stakingCancelWithout')
      // console.log(err)
    }
  },

  /**
   * 출금 요청
   * * @param {String} address 보낸 지갑 주소
   * * @param {number} coin 보낸 klay양
   * * @param {number} coinType 0: klay, 1: tips, 2: kida
   */
  async askingWithdraw (address, coin, coinType = 1) {
    try {
      const postData = {
        address: address,
        coin: coin,
        coin_type: coinType
      }
      return client.json.put('/wallet/asking', postData)
    } catch (err) {
      // console.log('<POST> wallet.stakingCancelWithout')
      // console.log(err)
    }
  },

  /**
   * 이자 받은 리스트
   * @param {number} count 보여줄 게시글 수
   * @param {number} nowPage 현재 페이지 번호 0부터 시작
   */
  async getFeeList (count, nowPage) {
    const postData = {
      count: count,
      nowpage: nowPage
    }
    try {
      return client.json.get('/coin-history', { params: postData })
    } catch (err) {
      // console.log(err)
    }
  },

  /**
   * 스테이킹 리스트 가져오기
   */
  async getStakingList () {
    try {
      return client.json.get('/staking')
    } catch (err) {
      // console.log('<GET> wallet.getStakingList')
      // console.log(err)
    }
  },

  /**
   * 스테이킹 이자 총합 가져오기
   */
  async getTotalFeeList () {
    try {
      return client.json.get('/coin-history/total')
    } catch (err) {
      // console.log('<GET> wallet.getStakingList')
      // console.log(err)
    }
  },

  /**
   * 지갑 추가
   * @param {String} address 지갑 주소
   */
  async push (address) {
    const postData = {
      address: address
    }
    try {
      return client.json.post('/wallet', postData)
    } catch (err) {
      // console.log('<POST> wallet.push')
      // console.log(err)
    }
  },

  /**
   * 지갑 가져오기
   */
  async getList () {
    try {
      return client.json.get('/wallet')
    } catch (err) {
      // console.log('<POST> wallet.getList')
      // console.log(err)
    }
  },
  /**
   * 스테이킹 하기(로그인 필수)
  //  * @param {String} address 지갑 주소
   * @param {number} coin 스테이킹 수량
   */
  async staking (coin) {
    const postData = {
      id: uuidv4(),
      coin: coin
    }
    try {
      return client.json.post('/staking', postData)
    } catch (err) {
      // console.log('<POST> wallet.staking')
      // console.log(err)
    }
  },

  /**
   * 스테이킹 취소(로그인 필수)
   * @param {number} stakingId 스테이킹 아이디
   */
  async stakingCancel (stakingId) {
    try {
      return client.json.delete(`/staking/${stakingId}`)
    } catch (err) {
      // console.log('<POST> wallet.stakingCancel')
      // console.log(err)
    }
  },

  /**
   * 지갑 스테이킹
   * * @param {String} address 지갑 주소
   * * @param {String} tx 해시 값
   * * @param {number} coin 스테이킹 수량
   */
  async stakingWithout (address, tx, coin) {
    try {
      const postData = {
        address: address,
        block_hash: tx,
        coin: coin.toString()
      }
      return client.json.post('/staking-without', postData)
    } catch (err) {
      // console.log('<POST> wallet.stakingWithout')
      // console.log(err)
    }
  },

  /**
   * 지갑 스테이킹 취소
   * * @param {String} address 지갑 주소
   */
  async stakingCancelWithout (address) {
    try {
      const postData = {
        address: address
      }
      return client.json.patch('/staking-without', postData)
    } catch (err) {
      // console.log('<POST> wallet.stakingCancelWithout')
      // console.log(err)
    }
  },

  /**
   * 지갑 스테이킹 확인
   * * @param {String} address 지갑 주소
   */
  async getStakingAddressList (address) {
    try {
      const postData = {
        address: address
      }
      return client.json.post('/staking/address', postData)
    } catch (err) {
      // console.log('<POST> wallet.getStakingAddressList')
      // console.log(err)
    }
  },

  /**
   * 어드민에게 KDIA 보낸 내역 확인
   * @param {number} count 보여줄 게시글 수
   * @param {number} nowPage 현재 페이지 번호 0부터 시작
   */
  async getSendKdiaList (count, nowPage) {
    try {
      const postData = {
        count: count,
        nowpage: nowPage
      }
      return client.json.get('/coin-history/admin/send-kdia', { params: postData })
    } catch (err) {
      // console.log('<POST> wallet.getStakingAddressList')
      // console.log(err)
    }
  },

  /**
   * 어드민에게 KDIA 보내기
   * @param {String} address 보낸 주소
   * @param {String} kdia 보낸 양
   */
  async getSendKdia (address, kdia) {
    try {
      const postData = {
        address: address,
        kdia: kdia
      }
      return client.json.post('/coin-history/admin/send-kdia', postData)
    } catch (err) {
      // console.log('<POST> wallet.getStakingAddressList')
      // console.log(err)
    }
  }
}
