<template>
    <div class="wrapper" :style="{ width: '100%', background: color, padding: padding ?? '50px 0'}">
        <div :style="{ width: '100%', background: color, display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                <div class="my-width">
                    <slot />
            </div>
          </div>
      </div>
</template>
<script>

export default {
  name: 'SetWidth',
  props: ['color', 'padding'],
  components: {
  }
}
</script>
