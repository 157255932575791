// import Web3 from 'web3'
// import { kdiaCrowdSale } from '../../abi/kdiaCrowdSale'
import wallet from '../wallet' // 메인
// import { TronLinkAdapter } from '@tronweb3/tronwallet-adapters'
// import TronWeb from 'tronweb'
// const tokenAddress = 'TP2jCch77RFra7PRAz8QrMW1sCyieAL6Uh'
// const crowdsaleAddress = 'TQD8TiigjJ5kpTQqGaiXwnSJQfyAjsVyL3' // 테스트
const crowdsaleAddress = 'TP91kKFDTzA25mkR1ciZhP2rpbQcm5zbp8' // 메인

let tronWeb

const ConnectTronLink = async () => {
  try {
    if (window.tronLink.ready) {
      tronWeb = window.tronLink.tronWeb
    } else {
      const res = await window.tronLink.request({ method: 'tron_requestAccounts' })
      if (res.code === 200) {
        tronWeb = window.tronLink.tronWeb
      } else {
        alert('TronLink 지갑 잠금 해제를 해주세요.')
      }
    }
  } catch (err) {
    const param = JSON.stringify({ url: 'https://www.qisico.org/', action: 'open', protocol: 'tronlink', version: '1.0' })
    const location = `tronlinkoutside://pull.activity?param=${encodeURI(param)}`
    window.location.href = location
    // alert(err.toString())
  }
}

/**
   * 지갑 연결
   * @param {*} setAddress action setAddress
   * @param {*} setAmount action setAmount
   * @param {*} updateWalletStaking action updateWalletStaking
   */
async function connect (setAddress, setAmount) {
  await ConnectTronLink()
  try {
    // const account = await window.tronLink.request({ method: 'tron_requestAccounts' })
    const account = await tronWeb.defaultAddress.base58
    // const accounts = await tronWeb.address.fromHex((await tronWeb.trx.getAccount()).address)

    setAddress(account)

    // const weiBalance = await tronWeb.trx.getBalance(account)
    // const balance = tron.utils.fromSun(weiBalance.toString())
    // setAmount(balance)
    // console.log(balance)
    return true
  } catch (err) {
    // console.log(err)
    return false
  }
}

/**
   * 토큰 구매(지갑으로 바로)
   * @param {*} amount Klaytn 송금양
   */
async function buy (amount) {
  await ConnectTronLink()

  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    if (tronWeb !== undefined) {
      const myAddress = await tronWeb.defaultAddress.base58

      // 보낼 토큰 양
      const tokenOwnerValue = tronWeb.toSun(amount)

      const contractInstance = await tronWeb.contract().at(crowdsaleAddress)
      // const contractInstance = await tronWeb.contract(kdiaCrowdSale, crowdsaleAddress)

      // 컨트랙트에 보낼 데이터
      const tx = {
        feeLimit: 100000000,
        shouldPollResponse: true,
        callValue: tokenOwnerValue,
        to: crowdsaleAddress,
        from: myAddress
      }

      // await contractInstance.invest().send(tx).then(res => {
      //   console.log(res)
      //   alert('구매 성공했습니다.')
      //   wallet.purchaseByWallet(amount * 100, 2, myAddress)
      // })

      await contractInstance.invest().send(tx)
      wallet.purchaseByWallet(amount * 100, 2, myAddress)
      alert('구매 성공했습니다.')
    }
  } catch (err) {
    // console.log(err)
    return false
  }
}

export default {
  connect, buy
}
