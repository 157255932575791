import Web3 from 'web3'
import MetaMaskSDK from '@metamask/sdk'
import { crowdsaleAbi } from '../../abi/crowdsaleabi'
import { abi } from '../../abi/abi'
import Decimal from 'decimal.js'
import wallet from '../wallet'

// const network = 1001 // klaytn testnet 1001
const chainId = '0x3e9' // klaytn testnet 0x3e9  1001
const tokenAddress = '0x981AeB68B7A9d1B3d9341636D0f45660995C6Af5'
const crowdsaleAddress = '0x180a38596054b16F85F517966b635C9089Ec006c'
// 스테이킹 풀 주소
const stakingAddress = '0x87e02340c9c5dab434d2e9f5cdbc3da06b8f47da'
let ethereum
let web3

const ConnectMetamask = async () => {
  if (window.ethereum) {
    ethereum = window.ethereum
  } else {
    const options = {
      injectProvider: false,
      communicationLayerPreference: 'webrtc'
    }
    const MMSDK = new MetaMaskSDK(options)
    const provider = MMSDK.getProvider()
    ethereum = provider
  }
  web3 = new Web3(ethereum)
}// OK

async function chainChanged () {
  const currentChainId = await ethereum.request({
    method: 'eth_chainId'
  })
  if (currentChainId === chainId) return true
  return false
}
async function switchNetwork () {
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }]
    })
  } catch {
    if (chainId !== 1001) {
      web3.eth.currentProvider.request({
        jsonrpc: '2.0',
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: Web3.utils.toHex(chainId),
            chainName: 'Klaytn Baobab',
            rpcUrls: ['https://public-node-api.klaytnapi.com/v1/baobab'],
            nativeCurrency: {
              name: 'TKLAY',
              symbol: 'TKLAY',
              decimals: 18
            },
            blockExplorerUrls: ['https://baobab.scope.klaytn.com/']
          }
        ],
        id: 0
      // eslint-disable-next-line handle-callback-err
      }).catch((error) => {
        // Error returned when rejected
        // console.error(error)
      })
    }
  } finally {
    if (!await chainChanged()) {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }]
      })
    }
  }
};

/**
   * 지갑 연결
   * @param {*} setAddress action setAddress
   * @param {*} setAmount action setAmount
   * @param {*} updateWalletStaking action updateWalletStaking
   */
async function connect (setAddress, setAmount, updateWalletStaking) {
  await ConnectMetamask()
  try {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
    if (!await chainChanged()) {
      await switchNetwork()
    }
    setAddress(accounts[0])
    // await checkCustomBalance(accounts[0], setAmount)
    const weiBalance = await web3.eth.getBalance(accounts[0])
    const balance = web3.utils.fromWei(weiBalance, 'ether')
    setAmount(balance)
    return true
  } catch (err) {
    return false
  }
}

/**
   * 토큰 구매(지갑으로 바로)
   * @param {*} amount Klaytn 송금양
   */
async function buy (amount) {
  await ConnectMetamask()

  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    if (web3 !== undefined) {
      const accounts = await web3.eth.getAccounts()

      // 내 주소
      const myAddress = accounts[0]
      // 보낼 토큰 양
      const tokenOwnerValue = web3.utils.toHex(web3.utils.toWei(amount.toString(), 'ether'))

      // @ts-ignore
      const contractInstance = new web3.eth.Contract(crowdsaleAbi, crowdsaleAddress, {
        from: myAddress
      })

      // 컨트랙트에 보낼 데이터
      const tx = {
        from: myAddress,
        to: crowdsaleAddress,
        // gas: '158650', // 158650
        // gasPrice: '30000000000', // 40gwie
        value: tokenOwnerValue,
        data: contractInstance.methods.invest().encodeABI()
      }

      web3.eth.sendTransaction(tx, async function (error, hash) {
        if (!(error)) {
          alert('구매 성공했습니다.')
          wallet.purchaseByWallet(amount * 2, 1, myAddress)
        } else {
        }
      })
    }
  } catch (err) {
    alert(err.toString())
    return false
  }
}

/**
   * 토큰 구매(충전)
   * @param {*} amount KLAY 송금양
   * @param {*} decimalPolyt TIPS 충전양
   * @param {*} ownCoin TIPS 보유양
   * @param {*} walletCoin 지갑에 보유중인 TIPS
   * @param {*} updateCoin 보유중인 TIPS update
   * @param {*} setAmount 지갑 TIPS update
   */
async function charging (amount, decimalPolyt, ownCoin, walletCoin, updateCoin, setAmount) {
  await ConnectMetamask()

  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }
    if (web3 !== undefined) {
      const accounts = await web3.eth.getAccounts()

      const tx = {
        from: accounts[0],
        to: stakingAddress,
        // gas: '158650', // 158650
        // gasPrice: '30000000000', // 40gwie
        value: web3.utils.toHex(web3.utils.toWei(amount.toString(), 'ether'))
      }
      web3.eth.sendTransaction(tx, async function (error, hash) {
        if (!(error)) {
          wallet.purchase(amount, accounts[0]).then(res => {
            if (res.data.resultCode === 'false') {
              alert('서버 오류')
              return
            }
            updateCoin(Decimal.add(ownCoin, decimalPolyt))
            setAmount(Decimal.sub(walletCoin, amount))
          })
          alert('충전 성공했습니다.')
          window.location.reload()
        }
      })
    }
  } catch (err) {
    // console.log(err)
    return false
  }
}

async function checkCustomBalance (address, setAmount) {
  await ConnectMetamask()

  try {
    const tokenInst = new web3.eth.Contract(abi, tokenAddress)
    const weiBalance = await tokenInst.methods.balanceOf(address).call()
    const balance = web3.utils.fromWei(weiBalance, 'ether')
    setAmount(balance)
  } catch (err) {
    // console.log(err)
    return false
  }
}

async function walletStaking (amount, address, fn) {
  await ConnectMetamask()

  try {
    const tokenInst = new web3.eth.Contract(abi, tokenAddress)
    web3.eth.sendTransaction({
      from: address,
      to: tokenAddress,
      // gas: '158650', // 158650
      // gasPrice: '30000000000', // 40gwie
      value: '0x0',
      data: tokenInst.methods.transfer(stakingAddress, web3.utils.toHex(web3.utils.toWei(amount.toString(), 'ether'))).encodeABI()
    }, (err, res) => {
      if (err) {

      } else {
        fn()
      }
    })
  } catch (err) {
    // console.log(err)
    return false
  }
}

export default {
  connect, buy, charging, checkCustomBalance, walletStaking
}
