import Caver from 'caver-js'
// import { crowdsaleAbi } from '../../abi/crowdsaleAbi'
import axios from 'axios'
import Decimal from 'decimal.js'
import wallet from '../wallet'

const network = 1001 // klaytn testnet 1001
const chainId = '0x3e9' // klaytn testnet 0x3e9
const tokenAddress = '0x981AeB68B7A9d1B3d9341636D0f45660995C6Af5'
const crowdsaleAddress = '0x180a38596054b16F85F517966b635C9089Ec006c'
const stakingAddress = '0x87e02340c9c5dab434d2e9f5cdbc3da06b8f47da'
const { klaytn } = window
const caver = new Caver(klaytn)
const mobileCaver = new Caver()

const request = (reqKey) => {
  window.location.href = 'kaikas://wallet/api?request_key=' + reqKey
}

async function chainChanged () {
  if (klaytn) {
    const currentChainId = await caver.rpc.klay.getChainId()
    if (currentChainId === chainId) return true
    return false
  }
}
/**
   * 지갑 연결
   * @param {*} setAddress action setAddress
   * @param {*} setAmount action setAmount
   */
async function connect (isMobile, setModal, setAddress, setAmount) {
  if (isMobile) {
    await connectMobile(setAddress, setModal, setAmount)
    return
  }
  await connectPC(setAddress, setAmount)
}

/**
   * 토큰 구매(지갑으로 바로)
   * @param {*} amount Klaytn 송금양
   */
async function buy (isMobile, amount, myAddress) {
  if (isMobile) {
    await buyMobile(amount, myAddress)
    return
  }
  await buyPC(amount)
}

/**
   * 토큰 구매(충전)
   * @param {*} amount KLAY 송금양
   * @param {*} decimalPolyt TIPS 충전양
   * @param {*} ownCoin TIPS 보유양
   * @param {*} walletCoin 지갑에 보유중인 TIPS
   * @param {*} updateCoin 보유중인 TIPS update
   * @param {*} setAmount 지갑 TIPS update
   */
async function charging (isMobile, amount, decimalPolyt, ownCoin, walletCoin, updateCoin, setAmount, myAddress) {
  if (isMobile) {
    await chargingMobile(amount, decimalPolyt, ownCoin, walletCoin, updateCoin, setAmount, myAddress)
    return
  }
  await chargingPC(amount, decimalPolyt, ownCoin, walletCoin, updateCoin, setAmount, myAddress)
}

/**
   * 지갑 TIPS로 스테이킹
   * @param {*} amount TIPS 송금양
   */
async function walletStaking (isMobile, amount, act) {
  if (isMobile) {
    walletStakingMobile(amount, act)
    return
  }
  walletStakingPC(amount, act)
}

async function connectMobile (setAddress, setModal, setAmount) {
  try {
    const prepare = async () => {
      return axios.post(
        'https://api.kaikas.io/api/v1/k/prepare',
        {
          type: 'auth',
          chain_id: network,
          bapp: {
            name: 'TIPS'
          }
        }
      ).then(data => { request(data.data.request_key); return data.data.request_key })
    }
    const getRes = (requestKey) => {
      return axios.get(
        'https://api.kaikas.io/api/v1/k/result/' + requestKey
      )
    }
    prepare().then((requestKey) => {
      const intervalId = setInterval(() => {
        getRes(requestKey).then(async data => {
          if (data.data.status === 'completed') {
            setAddress(data.data.result.klaytn_address)
            setModal(true)
            clearInterval(intervalId)
          }
        })
      }, 3000)
    })
    return true
  } catch (err) {
    return false
  }
}

async function connectPC (setAddress, setAmount) {
  try {
    const accounts = await klaytn.enable()
    const checkChain = await chainChanged()
    if (!checkChain) {
      // 문제가 있음...
      return
    }
    setAddress(accounts[0])
    const weiBalance = await caver.klay.getBalance(accounts[0])
    const balance = caver.utils.convertFromPeb(weiBalance)
    setAmount(balance)
    return true
  } catch (err) {
    return false
  }
}

async function buyMobile (amount, myAddress) {
  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    // 보낼 토큰 양
    const tokenOwnerValue = mobileCaver.utils.convertToPeb(amount.toString(), 'KLAY')
    const abi = JSON.stringify(
      {
        inputs: [],
        name: 'invest',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
      }
    )

    const params = JSON.stringify([])
    const requestKey = await axios.post(
      'https://api.kaikas.io/api/v1/k/prepare',
      {
        type: 'execute_contract',
        chain_id: network,
        bapp: {
          name: 'TIPS'
        },
        transaction: {
          abi: abi,
          value: tokenOwnerValue,
          to: crowdsaleAddress,
          params: params
        }
      }
    ).then(data => {
      request(data.data.request_key)
      return data.data.request_key
    })

    const interval = setInterval(async () => {
      const res = await axios.get(
        'https://api.kaikas.io/api/v1/k/result/' + requestKey
      ).then(data => data.data.result)
      if (res != null) {
        // walletState.setCurrentHash(res.tx_hash);
        alert('구매 성공했습니다.')
        wallet.purchaseByWallet(amount * 2, 1, myAddress)
        clearInterval(interval)
      }
    }, 3000)
  } catch (err) {
    alert(err.toString())
    return false
  }
}

async function buyPC (amount) {
  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    if (caver !== undefined) {
      const accounts = await caver.klay.getAccounts()

      // 내 주소
      const myAddress = accounts[0]
      // 보낼 토큰 양
      const tokenOwnerValue = caver.utils.convertToPeb(amount.toString(), 'KLAY')
      const data = caver.klay.abi.encodeFunctionCall(
        {
          inputs: [],
          name: 'invest',
          outputs: [],
          stateMutability: 'payable',
          type: 'function'
        },
        [])
      const tx = {
        type: 'SMART_CONTRACT_EXECUTION',
        from: myAddress,
        to: crowdsaleAddress,
        value: tokenOwnerValue,
        gas: '158650', // 158650
        gasPrice: '30000000000', // 40gwie
        data
      }

      caver.klay.sendTransaction(tx).then((data) => {
        alert('구매 성공했습니다.')
        wallet.purchaseByWallet(amount * 2, 1, myAddress)
        // console.log(data)
        // walletState.setCurrentHash(data.transactionHash)
      })
    }
  } catch (err) {
    // console.log(err)
    return false
  }
}

async function chargingMobile (amount, decimalPolyt, ownCoin, walletCoin, updateCoin, setAmount, myAddress) {
  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    const requestKey = await axios.post(
      'https://api.kaikas.io/api/v1/k/prepare',
      {
        type: 'send_klay',
        chain_id: network,
        bapp: {
          name: 'TIPS'
        },
        transaction: {
          amount: amount.toString(),
          to: stakingAddress
        }
      }
    ).then(data => {
      request(data.data.request_key)
      return data.data.request_key
    })
    const interval = setInterval(async () => {
      const res = await axios.get(
        'https://api.kaikas.io/api/v1/k/result/' + requestKey
      ).then(data => data.data.result)
      if (res != null) {
        // walletState.setCurrentHash(res.tx_hash);
        wallet.purchase(amount, myAddress).then(res => {
          if (res.data.resultCode === 'false') {
            alert('서버 오류')
            return
          }
          updateCoin(Decimal.add(ownCoin, decimalPolyt))
          setAmount(Decimal.sub(walletCoin, amount))
        })
        alert('충전 성공했습니다.')
        window.location.reload()
        clearInterval(interval)
        return true
      }
    }, 3000)
  } catch (err) {
    // console.log(err)
    return false
  }
}

async function chargingPC (amount, decimalPolyt, ownCoin, walletCoin, updateCoin, setAmount, myAddress) {
  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }
    if (caver !== undefined) {
      const accounts = await caver.klay.getAccounts()

      const tx = {
        from: accounts[0],
        to: stakingAddress,
        value: caver.utils.convertToPeb(amount, 'KLAY'),
        gas: '158650', // 158650
        gasPrice: '30000000000' // 40gwie
      }
      caver.klay.sendTransaction(tx, async function (error, hash) {
        if (!(error)) {
          wallet.purchase(amount, myAddress).then(res => {
            if (res.data.resultCode === 'false') {
              alert('서버 오류')
              return
            }
            updateCoin(Decimal.add(ownCoin, decimalPolyt))
            setAmount(Decimal.sub(walletCoin, amount))
          })
          alert('충전 성공했습니다.')
          window.location.reload()
        }
      })
    }
  } catch (err) {
    // console.log(err)
    return false
  }
}

async function walletStakingMobile (amount, act) {
  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    const abi = JSON.stringify(
      {
        constant: false,
        inputs: [
          {
            name: '_to',
            type: 'address'
          },
          {
            name: '_value',
            type: 'uint256'
          }
        ],
        name: 'transfer',
        outputs: [
          {
            name: '',
            type: 'bool'
          }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      }
    )
    const params = JSON.stringify([stakingAddress, caver.utils.convertToPeb(amount, 'KLAY')])
    const requestKey = await axios.post(
      'https://api.kaikas.io/api/v1/k/prepare',
      {
        type: 'execute_contract',
        bapp: {
          name: 'Hyper Bridge'
        },
        transaction: {
          abi: abi,
          value: '0',
          to: tokenAddress,
          params: params
        }
      }
    ).then(data => {
      request(data.data.request_key)
      return data.data.request_key
    })
    const interval = setInterval(async () => {
      const res = await axios.get(
        'https://api.kaikas.io/api/v1/k/result/' + requestKey
      ).then(data => data.data.result)
      if (res != null) {
        act()
        clearInterval(interval)
      }
    }, 3000)
  } catch (err) {
    alert(err.toString())
    return false
  }
}

async function walletStakingPC (amount, act) {
  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    if (caver !== undefined) {
      const accounts = await caver.klay.getAccounts()
      // 내 주소
      const myAddress = accounts[0]
      // 보낼 토큰 양
      const data = caver.klay.abi.encodeFunctionCall(
        {
          constant: false,
          inputs: [
            {
              name: '_to',
              type: 'address'
            },
            {
              name: '_value',
              type: 'uint256'
            }
          ],
          name: 'transfer',
          outputs: [
            {
              name: '',
              type: 'bool'
            }
          ],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function'
        },
        [stakingAddress, caver.utils.convertToPeb(amount, 'KLAY')])
      caver.klay.sendTransaction({
        type: 'SMART_CONTRACT_EXECUTION',
        from: myAddress,
        to: tokenAddress,
        gas: '158650', // 158650
        gasPrice: '30000000000', // 40gwie
        value: '0',
        data
      }).then((data) => {
        act()
      })
    }
  } catch (err) {
    // console.log(err)
    return false
  }
}

export default {
  connect, buy, charging, walletStaking
}
