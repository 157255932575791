<template>
  <div>
    <b-modal id="PurchaseModal"
             v-model="modalShow"
             @hide="statChange"
             footer-class="justify-content-center"
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 v-show="modalType === 'buy'" class="modal-title">구매하기</h5>
        <h5 v-show="modalType === 'charging'" class="modal-title">충전하기</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

        <p v-show="modalType === 'buy'">구매하실 코인을 입력해주세요.</p>
        <p v-show="modalType === 'charging'">충전하실 코인을 입력해주세요.</p>
        <template>
          <h5 class="text-center">1{{ this.toToken }} = {{ this.ratio }}{{ this.fromToken }}</h5>
          <p v-show="modalType === 'buy'">구매: {{ this.polyt }} {{ this.toToken }}</p>
        <p v-show="modalType === 'charging'">충전: {{ this.polyt }} {{ this.toToken }}</p>
        <b-form class="comment-text d-flex align-items-center" action="javascript:void(0);">
        <b-form-input :placeholder="this.fromToken +'양을 입력해주세요.'" type="number" v-model="matic" class="rounded" @keyup="maticChange" />
        </b-form>
      </template>
      <template #modal-footer="{ cancel, ok }">
        <b-button size="sm" variant="danger" @click="cancelPurchase(cancel)">취소</b-button>
        <b-button size="sm" variant="success" @click="doPurchase(ok)">확인</b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
// import wallet from '../../../services/wallet/wallet'
// import walletConnet from '../../../services/wallet/walletConnet'
import metamask from '../../../services/wallet/metamask'
import kaikas from '../../../services/wallet/kaikas'
import klip from '../../../services/wallet/klip'
import tronLink from '../../../services/wallet/tronLink'
import { mapActions, mapGetters } from 'vuex'
import Decimal from 'decimal.js'

export default {
  name: 'PurchaseModal',
  props: [
    'value', 'modalType', 'fromToken', 'ratio', 'toToken', 'balance', 'wallet', 'coinType'
  ],
  data () {
    return {
      modalShow: this.value,
      matic: '',
      polyt: '0',
      reward: this.total_reward
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      walletState: 'WalletConnect/walletState',
      providerState: 'WalletConnect/providerState'
    })
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  },
  methods: {
    ...mapActions({
      updateCoin: 'Auth/doCheering',
      setAmount: 'WalletConnect/setAmount',
      setKlipQR: 'WalletList/setKlipQR'
    }),
    statKilpModal (open) {
      this.$emit('statKilpModal', open)
    },
    maticChange () {
      if (this.matic === '') {
        this.polyt = 0
        return
      }
      this.polyt = new Decimal(this.matic) / new Decimal(this.ratio)
    },
    statChange () {
      this.$emit('statAmountChange')
    },
    cancelPurchase (cancel) {
      this.matic = ''
      this.polyt = ''
      cancel()
    },
    async doPurchase (ok) {
      try {
        // const mobile = this.$isMobile()
        const decimalMatic = new Decimal(this.matic.toString())
        const decimalPolyt = new Decimal(this.polyt.toString())
        const ownCoin = new Decimal(this.userState.coin.toString())
        const walletCoin = new Decimal('0')
        // const walletCoin = mobile ? new Decimal('0') : new Decimal(this.balance.toString())

        if (decimalMatic < new Decimal(0) || decimalPolyt < new Decimal(0)) {
          alert('0보다 작은값은 불가능합니다.')
          return
        }

        if (this.coinType === 'KLAY') {
          if (this.modalType === 'charging') {
            if (this.wallet === 'Metamask') {
              await metamask.charging(decimalMatic, decimalPolyt, ownCoin, walletCoin, this.updateCoin, this.setAmount)
            }
            if (this.wallet === 'Kaikas') {
              await kaikas.charging(this.$isMobile(), decimalMatic, decimalPolyt, ownCoin, walletCoin, this.updateCoin, this.setAmount, this.walletState.address)
            }
            if (this.wallet === 'Klip') {
              await klip.charging(this.$isMobile(), decimalMatic, this.walletState.address, this.setKlipQR, this.statChange, this.statKilpModal, decimalPolyt, ownCoin, walletCoin, this.updateCoin, this.setAmount)
            }
            this.matic = ''
            this.polyt = ''
            ok()
          } else if (this.modalType === 'buy') {
            if (this.wallet === 'Metamask') {
              await metamask.buy(decimalMatic)
            }
            if (this.wallet === 'Kaikas') {
              await kaikas.buy(this.$isMobile(), decimalMatic, this.walletState.address)
            }
            if (this.wallet === 'Klip') {
              await klip.buy(this.$isMobile(), decimalMatic, this.walletState.address, this.setKlipQR, this.statKilpModal, this.setKlipQR)
            }
            this.matic = ''
            this.polyt = ''
            ok()
          }
        } else if (this.coinType === 'TRX') {
          if (this.wallet === 'TronLink') {
            this.matic = ''
            this.polyt = ''
            ok()
            await tronLink.buy(decimalMatic, decimalPolyt, ownCoin, walletCoin, this.updateCoin, this.setAmount)
          }
        }
      } catch (err) {
        // console.log(err)
      }
    }

  }
}
</script>
